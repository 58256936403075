import type { VideoMedia } from './useCmsMedia';
import type { CmsMedia } from "./useCmsMedia";
import type { CONTENT_TYPE } from "~/types/course.types";

export interface Course {
  id: number;
  courseTitle: string;
  type: 'course' | 'workshop';
  cmeCredits: number;
  duration: number;
  trailer: VideoMedia | null;
  teaser: string;
  description: string;
  teachers: Teacher[];
  specialization: string;
  chapters: {
    kickoff: Chapter[];
    regular: Chapter[];
    mastery: Chapter[];
  };
  learningGoals: string[];
  learningResources: LearningResource[];
  activityInformationAccepted: boolean;
  bookmarked: boolean;
  feedbackProvided: boolean;
  certificateStatus: 'basic' | 'pro' | 'pro-claimed' | null;
  preliminaryCourse?: CourseTeaser;
  associatedWorkshop?: CourseTeaser;
  updatedVersion?: CourseTeaser;
  kickoffAndMasteryQuizzesEnabled?: boolean;
}

export type CourseTeaser = {
  id: number;
  description: string;
  type: 'course' | 'workshop';
  title: string;
  teachers: string[];
  backgroundPattern: CmsMedia;
  cmeCredits: number;
  duration: number;
  progress: number;
  slug: string;
  specialization: string;
  teacherIllustration: CmsMedia;
  teaser: string;
  cases?: number;
  lessons?: number;
  quizzes?: number;
};

export interface Chapter {
  id: number;
  title: string;
  type: 'regular' | 'kickoff' | 'mastery';
  description: string;
  difficulty?: 'warmup' | 'advanced' | 'pro';
  contents: LearningActivity[];
  locked: boolean;
}

export interface Teacher {
  name: string;
  body: string;
  profilePicture: any;
  url: string;
}

export interface LearningActivity {
  id: number;
  title: string;
  type: CONTENT_TYPE.LESSON | CONTENT_TYPE.CASE | CONTENT_TYPE.QUIZ | CONTENT_TYPE.KICKOFF_QUIZ | CONTENT_TYPE.MASTERY_QUIZ | CONTENT_TYPE.COMPARISON;
  locked: boolean;
  access: 'member' | 'public' | null;
  video?: LessonVideo;
  duration?: number;
  teachers?: Teacher[];
  learningResourceDownloads?: LearningResource[];
  completed?: boolean;
  progress?: ActivityProgress;
  chapterContentId: number;
  rating?: number | null;
}

export interface ActivityProgress {
  finished: boolean;
  passed: boolean;
  score: null | number;
}

export interface LessonVideo {
  source: string;
  name: string;
  metaData: {
    thumbnailUrl: string;
    lengthInSeconds: number;
  };
}

export interface LearningResource {
  name: string;
  type: string;
  source: string;
  uuid: string;
  metaData: {
    fileName: string;
    fileSize: number;
  };
}

export default function useCourse () {
  const isLoading = ref(false);
  const fetchError = ref(null as any | null);

  const fetchCourse = async (courseId: string) => {
    return await useErrorHandling(async () => {
      if (!courseId) {
        throw new Error('Course ID is required to get course.');
      }

      isLoading.value = true;

      try {
        const response = await useAuthenticatedFetch<Course>(useApiUrl(`courses/${courseId}`));

        if (response.error.value) {
          throw response.error.value;
        }

        if (!response.data.value) {
          throw new Error('Invalid response from server.');
        }

        return response.data.value;
      } catch (error) {
        fetchError.value = error;
        throw error;
      } finally {
        isLoading.value = false;
      }
    }, 'Fetching course failed');
  };

  const addCourseBookmark = async (courseId: number) => {
    return useAuthenticatedFetch(useApiUrl(`courses/${courseId}/bookmarks`), { method: 'POST' });
  };

  const removeCourseBookmark = async (courseId: number) => {
    return useAuthenticatedFetch(useApiUrl(`courses/${courseId}/bookmarks`), { method: 'DELETE' });
  };

  const submitFeedback = async (courseId: number, rating: number, progress: number, feedback?: string) => {
    if (feedback === '') {
      feedback = undefined;
    }
    return useAuthenticatedFetch(useApiUrl(`courses/${courseId}/feedback`), { method: 'POST', body: { rating, progress, feedback }});
  };

  return {
    fetchError,
    isLoading,

    fetchCourse,
    addCourseBookmark,
    removeCourseBookmark,
    submitFeedback,
  };
}
