import { generateUUID } from "../../../utils/generationHelper";

const snackbarMessages = ref<{
    id: string;
    message: string;
  }[]>([]);

export const useSnackbarMessages = () => {
  const dispatchMessage = (message: string) => {
    const msg = {
      id: generateUUID(),
      message,
    };

    snackbarMessages.value.push(msg);

    return msg.id;
  };

  const dismissMessage = (id: string) => {
    const index = snackbarMessages.value.findIndex(message => message.id === id);
    snackbarMessages.value.splice(index, 1);
  };

  return {
    snackbarMessages,
    dispatchMessage,
    dismissMessage,
  };
};

export default useSnackbarMessages;