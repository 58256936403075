import { createGlobalState, createEventHook } from '@vueuse/core';
import type { Course, LearningActivity } from '~/composables/useCourse';
import { useCourseFeedback } from '~/stores/useCourseFeedback';
import { CONTENT_TYPE, COMPARISON_ID } from "~/types/course.types";

export const useCourseState = createGlobalState(
  () => {
    const { fetchCourse, addCourseBookmark, removeCourseBookmark } = useCourse();
    const { calculateProgress, storeFeedbackMilestone, feedbackNeeded } = useCourseFeedback();

    const courseLoadedHook = createEventHook();

    const state = reactive({
      isCourseDetailsExpanded: true,
      activeCourseId: null as string | null,
      course: null as Course | null,
      showFeedbackForm: false,
      feedbackFormSkippable: true,
      subView: null as string | null,
      displayClaimCertificateForm: false,
      isLoading: false,
    });

    // Getters
    const isCourseDetailsExpanded = computed(() => state.isCourseDetailsExpanded);
    const isLoading = computed(() => state.isLoading);

    const course = computed(() => state.course);

    const preliminaryCourse = computed(() => state.course?.preliminaryCourse );
    const hasPreliminaryCourse = computed(() => !!state.course?.preliminaryCourse );

    const associatedWorkshop = computed(() => state.course?.associatedWorkshop );
    const hasAssociatedWorkshop = computed(() => !!state.course?.associatedWorkshop);

    const updatedVersion = computed(() => state.course?.updatedVersion );
    const hasUpdatedVersion = computed(() => !!state.course?.updatedVersion );

    const isCourse = computed(() => state.course?.type === 'course');
    const isWorkshop = computed(() => state.course?.type === 'workshop');

    const showFeedbackForm = computed(() => state.showFeedbackForm);

    const feedbackFormSkippable = computed(() => state.feedbackFormSkippable);

    const displayClaimCertificateForm = computed(() => state.displayClaimCertificateForm);
    const isKickoffCompleted = computed(() => {
      if (!state.course) {
        return false;
      }

      if (!state.course.kickoffAndMasteryQuizzesEnabled) {
        return false;
      }

      return state.course.chapters.kickoff
        .flatMap(chapter => chapter.contents)
        .every(activity => {
          return activity.progress?.finished;
        });
    });

    const progress = computed(() => {
      return calculateProgress(lessons.value, cases.value, quizzes.value);
    });

    const chapters = computed(() => [
      ...course.value?.chapters.kickoff || [],
      ...course.value?.chapters.regular || [],
      ...course.value?.chapters.mastery || [],
    ]);

    const activities = computed(() => {
      const activities: LearningActivity[] = [];

      chapters.value.forEach(chapter => {
        chapter.contents.forEach(activity => {
          activities.push(activity);
        });
      });

      return activities;
    });

    const lessons = computed(() => {
      const lessons: LearningActivity[] = [];

      chapters.value?.forEach(chapter => {
        chapter.contents.forEach(activity => {
          if (activity.type === CONTENT_TYPE.LESSON) {
            lessons.push(activity);
          }
        });
      });

      return lessons;
    });

    const quizzes = computed(() => {
      const quizzes: LearningActivity[] = [];

      chapters.value?.forEach(chapter => {
        chapter.contents.forEach(activity => {
          if ([CONTENT_TYPE.QUIZ, CONTENT_TYPE.KICKOFF_QUIZ, CONTENT_TYPE.MASTERY_QUIZ].includes(activity.type)) {
            quizzes.push(activity);
          }
        });
      });

      return quizzes;
    });

    const cases = computed(() => {
      const cases: LearningActivity[] = [];

      chapters.value?.forEach(chapter => {
        chapter.contents.forEach(activity => {
          if (activity.type === 'case') {
            cases.push(activity);
          }
        });
      });

      return cases;
    });

    const subView = computed(() => state.subView || 'unknown');

    // Actions
    const setCourseId = async (courseId: string, load: boolean = true) => {
      state.activeCourseId = courseId;

      if (load) {
        return loadCourse(courseId);
      }
    };

    const refreshCourse = async () => {
      if (state.activeCourseId) {
        await loadCourse(state.activeCourseId);
      }
    };

    const toggleCourseDetails = () => {
      state.isCourseDetailsExpanded = !state.isCourseDetailsExpanded;
    };

    const setCourse = (course: any) => {
      state.course = course;
    };

    const setCourseActivityInformationAccepted = (courseId: number) => {
      if (state.course?.id === courseId) {
        state.course.activityInformationAccepted = true;
        setCourse(state.course);
      }
    };

    const loadCourse = async (courseId: string) => {
      try {
        state.isLoading = true;

        const course = await fetchCourse(courseId);

        appendComparison(course);
        setCourse(course);

        if (course && !course.feedbackProvided) {
          const progress = calculateProgress(lessons.value, cases.value, quizzes.value);

          if (progress >= 1) {
            state.feedbackFormSkippable = false;
            state.showFeedbackForm = true;
          } else {
            state.showFeedbackForm = feedbackNeeded(course.id, progress);
          }
        }

        courseLoadedHook.trigger(course);
      } finally {
        state.isLoading = false;
      }
    };

    const appendComparison = (course?: Course) => {
      if (!course || !course.kickoffAndMasteryQuizzesEnabled) {
        return;
      }

      if (course.chapters.mastery[0].contents.find(content => content.type === CONTENT_TYPE.COMPARISON)) {
        return;
      }

      const masteryQuizIndex = course.chapters.mastery[0].contents.findIndex(content => content.type === CONTENT_TYPE.MASTERY_QUIZ);
      const masteryQuiz = course.chapters.mastery[0].contents[masteryQuizIndex];

      const activity = {
        type: CONTENT_TYPE.COMPARISON,
        id: COMPARISON_ID,
        title: 'Comparison',
        locked: masteryQuiz?.progress?.finished !== true,
      } as LearningActivity;

      course.chapters.mastery[0].contents.splice(masteryQuizIndex + 1, 0, activity);
    };

    const getLesson = (lessonId: string) => {
      return lessons.value.find(lesson => lesson.id === parseInt(lessonId, 10)) || null;
    };

    const getQuiz = (quizId: string) => {
      return quizzes.value.find(quiz => quiz.id === parseInt(quizId, 10)) || null;
    };

    const getCase = (caseId: string) => {
      return cases.value.find(quiz => quiz.id === parseInt(caseId, 10)) || null;
    };

    const getActivity = (activityId: string) => {
      return activities.value.find(activity => activity.id === parseInt(activityId, 10)) || null;
    };

    const getPreviousActivity = (currentActivityId: string) => {
      const currentActivityIndex = activities.value.findIndex(activity => activity.id === parseInt(currentActivityId, 10));

      return activities.value[currentActivityIndex - 1] || null;
    };

    const getNextActivity = (currentActivityId: string) => {
      const currentAcitivityIndex = activities.value.findIndex(activity => activity.id === parseInt(currentActivityId, 10));

      return activities.value[currentAcitivityIndex + 1] || null;
    };

    const getChapterByActivity = (activityId: string) => {
      return chapters.value?.find(chapter => chapter.contents.find(content => content.id === parseInt(activityId, 10))) || null;
    };

    const getActivityUrl = (activity: LearningActivity) => {
      if (!course.value) {
        return '';
      }

      switch (activity.type) {
        case CONTENT_TYPE.LESSON:
          return `/courses/${course.value.id}/lessons/${activity.id}`;
        case CONTENT_TYPE.QUIZ:
        case CONTENT_TYPE.KICKOFF_QUIZ:
        case CONTENT_TYPE.MASTERY_QUIZ:
          return `/courses/${course.value.id}/quizzes/${activity.id}`;
        case CONTENT_TYPE.COMPARISON:
          return `/courses/${course.value.id}/comparison`;
      }
    };

    const toggleCourseBookmark = () => {
      if (!state.course) {
        return;
      }

      const stateBefore = state.course.bookmarked;
      let requestPromise;

      state.course.bookmarked = !state.course.bookmarked;

      if (state.course.bookmarked) {
        requestPromise = addCourseBookmark(state.course.id);
      } else {
        requestPromise = removeCourseBookmark(state.course.id);
      }

      return requestPromise.catch(() => {
        if (state.course) {
          state.course.bookmarked = stateBefore;
        }
      });
    };

    const closeFeedbackForm = (feedbackAction: 'remindMeLater' | 'submitted') => {
      if (!course.value) {
        return;
      }

      const progress = calculateProgress(lessons.value, cases.value, quizzes.value);
      storeFeedbackMilestone(course.value.id, progress, feedbackAction);
      state.showFeedbackForm = false;
    };

    const updateSubView = () => {
      const route = useRoute();
      const lastMatched = route.matched[route.matched.length - 1];
      state.subView = String(lastMatched ? lastMatched.name : 'unknown');
    };

    const claimCertificate = async () => {
      if (!state.course || !state.course.certificateStatus) {
        return;
      }

      if (state.course.certificateStatus === 'pro') {
        state.displayClaimCertificateForm = true;
        return;
      }

      let response;

      if (state.course.certificateStatus === 'basic') {
        response = await useCertificate().downloadBasicCertificate(state.course.id);
      } else {
        response = await useCertificate().downloadProCertificate(state.course.id);
      }

      if (response.error && response.error.value) {
        // TODO: show proper error when the component is ready
        useSnackbarMessages().dispatchMessage(response.error.value.data.message);
      } else {
        useCertificate().startDownload(response.data.value as BlobPart, `CME Certificate - ${state.course.courseTitle}.pdf`);
      }
    };

    const dismissCertificateForm = () => {
      state.displayClaimCertificateForm = false;
    };

    return {
      isCourseDetailsExpanded,
      isLoading,
      course,
      preliminaryCourse,
      hasPreliminaryCourse,
      associatedWorkshop,
      hasAssociatedWorkshop,
      updatedVersion,
      hasUpdatedVersion,
      isCourse,
      isWorkshop,
      activities,
      showFeedbackForm,
      feedbackFormSkippable,
      displayClaimCertificateForm,
      progress,
      subView,
      isKickoffCompleted,

      onCourseLoaded: courseLoadedHook.on,

      refreshCourse,
      toggleCourseDetails,
      toggleCourseBookmark,
      setCourseId,
      getLesson,
      getQuiz,
      getCase,
      getActivity,
      getPreviousActivity,
      getNextActivity,
      getChapterByActivity,
      setCourseActivityInformationAccepted,
      closeFeedbackForm,
      updateSubView,
      claimCertificate,
      dismissCertificateForm,
      getActivityUrl,
    };
  },
);