
export function useCertificate () {

  const downloadBasicCertificate = async (courseId: number) => {
    const url = useApiUrl('/courses/' + courseId + '/claim-certificate/basic');
    return useAuthenticatedFetch(url, { method: 'POST' });
  };

  const downloadProCertificate = async (courseId: number) => {
    const url = useApiUrl('/courses/' + courseId + '/claim-certificate/claimed');
    return useAuthenticatedFetch(url, { method: 'POST' });
  };

  const submitClaimCertificateForm = async (
    courseId: number,
    body: {[key: string]: string | boolean | string[] | number },
  ) => {
    const url = useApiUrl('/courses/' + courseId + '/claim-certificate/pro');
    return await useAuthenticatedFetch(url, { method: 'POST', body });
  };

  const startDownload = (data: BlobPart, fileName: string = 'certificate.pdf') => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return {
    downloadBasicCertificate,
    downloadProCertificate,
    submitClaimCertificateForm,
    startDownload,
  };
}